import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }
  //common methods
  truncateFileName(fileName: string, maxLength: number = 25): string {
    if (fileName.length <= maxLength) {
      return fileName;
    }
    const extension = fileName.split('.').pop();
    const nameWithoutExtension = fileName.slice(0, -(extension?.length ?? 0 + 1));
    const truncatedName = nameWithoutExtension.slice(0, maxLength - 3) + '...';
    return `${truncatedName}.${extension}`;
  }

  uploadedFileType(fileName: string | undefined): string {
    if (!fileName) {
      return 'unknown';
    }
    const extension = fileName.split('.').pop()?.toLowerCase() || '';
    if (extension === 'pdf') {
      return 'pdf';
    } else if (['doc', 'docx'].includes(extension)) {
      return 'doc';
    } else if (['png', 'jpg', 'jpeg'].includes(extension)) {
      return 'image';
    } else if (['xls', 'xlsx'].includes(extension)) {
      return 'xlsx';
    }
    return 'unknown';
  }

  openFileInNewTab(files: { name: string, url: string }[], index: number) {
    try {
      if (index >= 0 && index < files.length) {
        const fileUrl = files[index].url;
        window.open(fileUrl, '_blank');
      } else {
        console.error('Index out of bounds:', index);
      }
    } catch (error) {
      console.error('Failed to open file in new tab:', error);
    }
  }
  
}
