import { Routes } from '@angular/router';
import { TemplateViewerComponent } from './template-viewer/template-viewer.component';

export const routes: Routes = [
  { path: '', redirectTo: '/view-template', pathMatch: 'full' },
  // { path: 'dashboard', component: DashboardComponent },
  // { path: 'projects', component: ProjectsComponent },
  // { path: 'data-portal', component: DataPortalComponent },
  // { path: 'geological-info', component: GeologicalInformationComponent },
  // { path: 'subsurface-data', component: SubsurfaceDataComponent },
  // { path: 'climate-data', component: ClimateDataComponent },
  // { path: 'subsurface-water-data', component: SubsurfaceWaterDataComponent },
  // { path: 'borehole-data', component: BoreholeDataComponent },
  { path: 'view-template', component: TemplateViewerComponent }

];
