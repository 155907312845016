import { AfterViewChecked, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, Inject, NO_ERRORS_SCHEMA } from '@angular/core';
import { TemplateViewerService } from './template-viewer.service';
import { MatList, MatListItem } from '@angular/material/list';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatAccordion, MatExpansionModule, MatExpansionPanel, MatExpansionPanelDescription, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { CapitalizeFirstPipeModule } from '../pipes/capitaliza.module';
import { projectMessageLocal } from './project-message';
import { SnackbarService } from './snackbar.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IncompleteQuestionsDialogComponent } from '../incomplete-questions-dialog/incomplete-questions-dialog.component';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFileExcel, faFileImage, faFilePdf, faFileWord, faCircle } from '@fortawesome/free-solid-svg-icons';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { CongratulationsComponent } from '../congratulations/congratulations.component';
import { HttpClient, HttpClientModule, HttpHandler } from '@angular/common/http';
import { SharedService } from '../shared.service';
import {MatSelectModule} from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';


@Component({
  selector: 'app-template-viewer',
  standalone: true,
  imports: [IncompleteQuestionsDialogComponent, CommonModule, RouterOutlet, MatListItem, MatExpansionPanelDescription, MatList, MatExpansionPanelTitle,
    MatExpansionModule, MatExpansionPanel, MatAccordion, MatRadioButton, MatRadioModule, MatCheckboxModule,
    MatCheckbox, MatCardModule, CapitalizeFirstPipeModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule,
    MatIcon, MatProgressSpinnerModule, MatTooltipModule, FontAwesomeModule, MatStepperModule, MatProgressBarModule,
    CongratulationsComponent,MatSelectModule,MatOptionModule,FormsModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [TemplateViewerService], // Make sure TemplateViewerService is provided
  templateUrl: './template-viewer.component.html',
  styleUrls: ['./template-viewer.component.scss']
})
export class TemplateViewerComponent implements AfterViewChecked {
  data: any;
  completionPercentage: number = 0;
  key: any;
  startTime!: Date;
  uploading: boolean = false;
  progressValue = 5;
  faFilePdf = faFilePdf;
  faFileWord = faFileWord;
  faFileExcel = faFileExcel;
  faFileImage = faFileImage;
  faCircleIcon = faCircle;
  showCongratulations: boolean = false;
  activeStep: number = -1;
  surveyStarted: boolean = false;
  phoneNumber:string='+49 8022 18808886'
  selected=''

  constructor(public templateViewerService: TemplateViewerService, public sharedService: SharedService,
     private route: ActivatedRoute,
    private snackbarService: SnackbarService, private fb: FormBuilder,
    private dialog: MatDialog, public cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.key = params['key'];
    });
    this.startTime = new Date();
    this.loadTemplate(this.key);
  }
  startSurvey() {
    this.surveyStarted = true;
    this.activeStep = 0;
  }

  previousStep() {
    if (this.activeStep > 0) {
      this.activeStep--;
    }
  }

  nextStep() {
    if (this.activeStep < (this.data?.sections.length - 1)) {
      this.activeStep++;
    }
  }

  selectStep(index: number) {
    console.log("Step number:", index);
    this.activeStep = index;
  }

  loadTemplate(id: any) {
    this.templateViewerService.getSmaSurvey(id).subscribe({
      next: (response: any) => {
        // Parse stringified options
        this.parseOptions(response);
        console.log(response);
        this.data = response;
        this.initializeOptions();
        this.updateCompletion();
      },
      error: (error: any) => {
        console.log(error)
      }
    });
  }

  // Method to parse stringified options
  parseOptions(response: any) {
    if (response && response.sections) {
      response.sections.forEach((section: any) => {
        section.questions.forEach((question: any) => {
          if (question.options) {
            question.options = question.options.map((optionString: string) => {
              try {
                return JSON.parse(optionString);
              } catch (e) {
                console.error('Failed to parse option string:', optionString);
                return null; // Or handle the error as needed
              }
            }).filter((option: any) => option !== null);
          }
        });
      });
    }
  }

  updateCompletion() {
    let totalQuestions = 0;
    let completedQuestions = 0;

    this.data.sections.forEach((section: any) => {
      section.questions.forEach((question: any) => {
        totalQuestions++;
        if (question.answer && question.answer.trim() !== '') {
          completedQuestions++;
        } else if (question.type === 'checkbox') {
          if (question.options.some((option: any) => option.checked)) {
            completedQuestions++;
          }
        } else if (question.type === 'radio') {
          if (question.selectedOption) {
            completedQuestions++;
          }
        }
      });
    });

    this.completionPercentage = (completedQuestions / totalQuestions) * 100;
  }


  updateSurvey() {
    const incompleteQuestions = this.validateSurvey();
    if (incompleteQuestions.length > 0) {
      this.dialog.open(IncompleteQuestionsDialogComponent, {
        height: "600px",
        data: { incompleteQuestions, submittedQuestions: [], confirmation: false },
        autoFocus: false, // Disable auto focus
      });
      return;
    }
    const submittedQuestions = this.getSubmittedQuestions();
    this.dialog.open(IncompleteQuestionsDialogComponent, {
      height: "600px",
      data: { incompleteQuestions: [], submittedQuestions, confirmation: true },
      autoFocus: false, // Disable auto focus
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        const surveyData = this.surveyDataForAPI(this.data);
        console.log(surveyData);
        this.templateViewerService.UpdateSurvey(surveyData).subscribe({
          next: (response: any) => {
            if (response) {
              console.log(response);
              this.showCongratulations = true;
              // let message: string = projectMessageLocal.SURVEY_ADDED
              // this.snackbarService.openSnackBar(message, { panelClass: 'snackbarnormal' });
              this.resetTimer(); // Reset the timer after successful submission
            }
          },
          error: (error: any) => {
            console.log(error);
            let errorMsg: string = projectMessageLocal.SURVEY_FAILED;
            this.snackbarService.openSnackBar(errorMsg, { panelClass: 'snackbarerror' })
          }
        });
      }
    });
  }

  validateSurvey(): string[] {
    const incompleteQuestions: string[] = [];
    this.data.sections.forEach((section: any) => {
      section.questions.forEach((question: any) => {
        if (question.required) {
          let answer;
          if (question.type === 'checkbox') {
            answer = question.options.some((option: any) => option.checked);
          } else if (question.type === 'radio') {
            answer = question.selectedOption;
          } else if (question.type === 'input' || question.type === 'textarea') {
            answer = question.answer;
          }
          if (!answer || (Array.isArray(answer) && answer.length === 0)) {
            incompleteQuestions.push(question.text);
          }
        }
      });
    });
    return incompleteQuestions;
  }

  getSubmittedQuestions(): any {
    const sections: any[] = [];

    this.data.sections.forEach((section: any) => {
      const sectionData: any = {
        title: section.title,
        description: section.description || '',
        questions: []
      };

      section.questions.forEach((question: any) => {
        const questionData: any = {
          text: question.text,
          description: question.description || '',
          type: question.type,
          options: [],
          answer: ''
        };

        if (question.type === 'checkbox') {
          questionData.options = question.options.map((option: any) => {
            if (typeof option.value === 'object') {
              return option.value.answer || JSON.stringify(option.value);
            }
            return option.value;
          });

          const selectedOptions = question.options
            .filter((option: any) => option.checked)
            .map((option: any) => {
              if (typeof option.value === 'object') {
                return option.value.answer || JSON.stringify(option.value);
              }
              return option.value;
            });

          if (selectedOptions.length) {
            questionData.answer = selectedOptions;
          }
        } else if (question.type === 'radio') {
          questionData.options = question.options.map((option: any) => option.answer);

          if (question.selectedOption) {
            questionData.answer = question.selectedOption;
          }
        } else if (question.type === 'input' || question.type === 'textarea') {
          if (question.answer) {
            questionData.answer = question.answer;
          }
        }
        if (question.fileUpload && question.uploadedFiles && question.uploadedFiles.length > 0) {
          questionData.uploadedFiles = question.uploadedFiles.map((file: any) => ({
            name: file.name,
            url: file.url
          }));
          questionData.fileUpload = question.fileUpload
        }
        sectionData.questions.push(questionData);
      });

      sections.push(sectionData);
    });

    return sections;
  }

  private surveyDataForAPI(data: any): any {
    let isComplete = true;
    const sections = data.sections.map((section: any) => ({
      title: section.title,
      description: section.description,
      questions: section.questions.map((question: any) => {
        let answer;
        if (question.type === 'checkbox') {
          answer = question.options.filter((option: any) =>
            option.checked).map((option: any) =>
              option.value);
        } else if (question.type === 'radio') {
          answer = question.selectedOption;
        } else if (question.type === 'input' || question.type === 'textarea') {
          answer = question.answer;
        }
        if (!answer || (Array.isArray(answer) && answer.length === 0)) {
          isComplete = false;
        }
        const uploadedFile = question.uploadedFiles ? question.uploadedFiles : null;
        return {
          description: question.description,
          text: question.text,
          type: question.type,
          options: question.type === 'checkbox' || question.type === 'radio' ? question.options : null,
          answer: answer,
          uploadedFile: uploadedFile // Add uploadedFile to the question object
        };
      })
    }))
    console.log(sections);
    const endTime = new Date();
    const duration = this.formatDuration(endTime.getTime() - this.startTime.getTime()); // Duration in timestamp format
    return {
      surveyId: data.id,
      ctxId: data.ctxId,
      userId: data.userId,
      title: data.title,
      description: data.description,
      image: data.image,
      questions: JSON.stringify(sections),
      status: isComplete ? 'COMPLETED' : 'INCOMPLETE',
      duration: duration // Include duration in the API data
    };
  }

  initializeOptions() {
    this.data.sections.forEach((section: any) => {
      section.questions.forEach((question: any) => {
        if (question.type === 'checkbox') {
          question.options = question.options.map((option: any) => ({
            value: option,
            checked: false
          }));
        }
        if (question.type === 'radio' && question.options.some((option: any) => option.answer === 'Yes' || option.answer === 'No')) {
          question.showFurtherOptions = false;
        }
      });
    });
  }

  onCheckboxChange(question: any, option: any) {
    option.checked = option.checked;
    this.updateCompletion();
    this.cd.detectChanges();
  }

  onRadioChange(question: any, selectedOption: string) {
    question.selectedOption = selectedOption;
    this.updateCompletion();
    if (selectedOption === 'Yes') {
      // question.showFurtherOptions = true;
    } else {
      // question.showFurtherOptions = false;
    }
  }

  getGlobalQuestionIndex(sectionIndex: number, questionIndex: number): number {
    let globalIndex = 0;
    for (let i = 0; i < sectionIndex; i++) {
      globalIndex += this.data.sections[i].questions.length;
    }
    globalIndex += questionIndex;
    return globalIndex;
  }

  resetTimer() {
    this.startTime = new Date(); // Reset the start time for the next survey attempt
  }

  private formatDuration(durationInMilliseconds: number): string {
    const totalSeconds = Math.floor(durationInMilliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  }

  onFileChange(event: any, quesAns: any): void {
    const files: File[] = Array.from(event.target.files);
    const allowedFileTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'png', 'jpg', 'jpeg'];
    const existingFiles = quesAns.uploadedFiles || [];
    // Check if the number of selected files plus existing files exceeds the limit
    if (existingFiles.length + files.length > 5) {
      this.snackbarService.openSnackBar('You can only upload a maximum of 5 files.', { panelClass: 'snackbarerror' });
      return;
    }

    // Check if the number of selected files exceeds the limit
    if (files.length > 5) {
      this.snackbarService.openSnackBar('You can only upload a maximum of 5 files.', { panelClass: 'snackbarerror' });
      return;
    }
    // Check if all files are of the allowed types
    const invalidFiles = files.filter(file => {
      const extension = file.name.split('.').pop()?.toLowerCase();
      return !allowedFileTypes.includes(extension!);
    });

    if (invalidFiles.length > 0) {
      this.snackbarService.openSnackBar('Only PDF, Word, Excel, and Image files can be uploaded.', { panelClass: 'snackbarerror' });
      return;
    }
    if (files.length > 0) {
      const formData: FormData = new FormData();
      files.forEach((file) => {
        formData.append('file', file);
      });
      quesAns.uploading = true;
      this.templateViewerService.fileUpload(formData).subscribe({
        next: (response: any) => {
          console.log('API Response:', response);
          const fileUrl: string[] = JSON.parse(response);
          // Assuming response is a single URL or similar
          if (fileUrl.length === files.length) {
          quesAns.uploadedFiles = files.map((file, index) => ({
            name: file.name,
            url: fileUrl[index]
          }));
        }
          quesAns.uploadedFiles = [...existingFiles, ...quesAns.uploadedFiles];
          quesAns.uploading = false;
          this.updateCompletion();
          this.snackbarService.openSnackBar('Files uploaded successfully.', { panelClass: 'snackbarnormal' });
        },
        error: (error) => {
          console.log('error', error);
          this.snackbarService.openSnackBar('File upload failed.', { panelClass: 'snackbarerror' });
          quesAns.uploading = false;
        }
      });
    }
  }

  

  removeFile(quesAns: any, index: number): void {
    quesAns.uploadedFiles.splice(index, 1);
    this.snackbarService.openSnackBar('File removed successfully.', { panelClass: 'snackbarnormal' });
    this.updateCompletion();
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  getPointerPosition(): string {
    return `${this.completionPercentage}%`;
  }

  getDropdownList(dropdownAns:any):any{

    console.log(dropdownAns)
    const answer: string = dropdownAns;
  let answerArray: { name: string }[];
  if(answer.includes('\n')){
    answerArray=  answer?.split('\n').map(item => ({ name: item.trim() }));
    console.log('answerArray',answerArray)
  }
  else{
    answerArray=  answer?.split(',').map(item => ({ name: item.trim() }));
    console.log('answerArray',answerArray)
  }
 
  return answerArray
 
  }
  trackByFn( item:any) {
    return item.name; // or any unique identifier
  }
}