import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { projectMessageLocal } from "./project-message";

@Injectable({
    providedIn: 'root'
})

export class SnackbarService {
    TIMEOUT_DELAY_LARGE= projectMessageLocal.TIMEOUT_DELAY_LARGE;

    constructor(private snackBar: MatSnackBar) {}

    openSnackBar(message: string, params: any = []) {
        const panelclass = (params['panelClass']) ?
            params['panelClass'] : 'snackbarnormal';
        if (params['panelClass'] === 'snackbarerror') {
            // message = this.getApiError(message);
        }
        let duration = this.TIMEOUT_DELAY_LARGE;
        if (params['duration']) {
            duration = params['duration'];
        }
        const snackBarRef = this.snackBar.open(message, '', {
            duration: duration, panelClass: panelclass,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
        });
        return snackBarRef;
    }
    //   getApiError(error:string){
    //    let API_ERROR = projectMessageLocal.API_ERROR;
    //     if (error && typeof error === 'string') {
    //         return error;
    //     } else if (typeof error === 'string') {
    //         return error;
    //     } else {
    //         return API_ERROR;
    //     }
    //   }
}