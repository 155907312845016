<div class="content" *ngIf="!showCongratulations; else congratsTemplate">
  <div class="about-contact-section">
    <div class="layout-container">
      <!-- Sidebar for displaying stepper navigation -->
      <div class="sidebar-container">
        <div class="sidebar-content">
          <div class="logo-container">
            <!-- <img *ngIf="data?.image" loading="lazy" [src]="data.image" alt="Logo Image" class="logo-image"> -->
            <img src="https://siera-alliance.org/wp-content/uploads/2024/07/Suera-Alliance-logo-design-02-e1720774375866-1024x357.png" loading="lazy"  alt="Logo Image" class="logo-image"/>
          </div>
          <div class="title-container">
            <div class="assessment_label_bg">
              {{ data?.title | capitalizeFirst }}
            </div>
          </div>
          <div class="step-titles">
            <div *ngFor="let section of data?.sections; let sectionIndex = index" 
                 (click)="selectStep(sectionIndex)"
                 class="step-item"
                 [ngClass]="{'active-step': activeStep === sectionIndex}">
              <div class="step-icon">
                <mat-icon *ngIf="activeStep > sectionIndex">check_circle</mat-icon>
                <mat-icon *ngIf="activeStep === sectionIndex">radio_button_checked</mat-icon>
                <mat-icon *ngIf="activeStep < sectionIndex">radio_button_unchecked</mat-icon>
              </div>
              <div class="step-title">
                {{ section.title | capitalizeFirst }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Main content area for displaying step content -->
      <div class="main-content">
        <div class="phone-number-container">
          <mat-icon class="phone-icon">phone</mat-icon>
          <span class="phone-number"><a class="phoneNumber" [href]="'tel:' + phoneNumber">{{ phoneNumber }}</a></span>
        </div>
        <ng-container *ngIf="data?.sections.length">
          <div *ngIf="surveyStarted; else descriptionTemplate">
            <div class="card_bg_mgn">
              <div class="card card_bg">
                <!-- Survey content for steps -->
                <ng-container *ngIf="activeStep >= 0">
                  <div class="survey_description_section mgn-up-10" [innerHTML]="data?.sections[activeStep]?.description | capitalizeFirst"></div>
                  <div class="background_panel">
                    <ng-container *ngIf="data?.sections[activeStep]?.questions?.length">
                      <div class="question-table">
                        <ng-container *ngFor="let quesAns of data?.sections[activeStep]?.questions; let questionIndex = index">
                          <div class="table-cell">
                            <div class="ques_num_label">{{ getGlobalQuestionIndex(activeStep, questionIndex) + 1 }}.</div>
                            <div class="card_bg_custom custom_panel_expansion">
                              <div class="question_label">
                                <ng-container *ngIf="quesAns.text">
                                  <span>{{ quesAns.text | capitalizeFirst }}</span>
                                  <span *ngIf="quesAns.required" class="required-asterisk">*</span>
                                  <div class="question_description_section mgn-up-10" *ngIf="quesAns.description">
                                    {{ quesAns.description | capitalizeFirst }}
                                  </div>
                                </ng-container>
                              </div>
                              <div class="input-container">
                                <ng-container *ngIf="quesAns.type === 'checkbox'">
                                  <ng-container *ngFor="let option of quesAns.options; let optionIndex = index">
                                    <label class="custom-checkbox">
                                      <input type="checkbox" [(ngModel)]="option.checked" (change)="onCheckboxChange(quesAns, option)">
                                      <span class="checkmark"></span>
                                      {{ option.value.answer | capitalizeFirst }}
                                    </label>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="quesAns.type === 'radio'">
                                  <ng-container *ngFor="let option of quesAns.options">
                                    <label class="custom-radio">
                                      <input type="radio" name="radioGroup{{questionIndex}}" [(ngModel)]="quesAns.selectedOption" (ngModelChange)="onRadioChange(quesAns, $event)" [value]="option.answer">
                                      <span class="radiomark"></span>
                                      {{ option.answer | capitalizeFirst }}
                                    </label>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="quesAns.type === 'textarea'">
                                  <ng-container *ngFor="let option of quesAns.options">
                                    <textarea class="custom-textarea" [(ngModel)]="quesAns.answer" (ngModelChange)="updateCompletion()" [placeholder]="option?.placeholder"></textarea>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="quesAns.type === 'input'">
                                  <ng-container *ngFor="let option of quesAns.options">
                                    <input disabled="{{option.answer.includes('IRO-')}}" type="{{quesAns.inputType || 'text'}}" class="custom-input" [(ngModel)]="option.answer" (ngModelChange)="updateCompletion()" [placeholder]="option?.placeholder">
                                  </ng-container>
                                </ng-container>

                                <ng-container *ngIf="quesAns.type === 'dropdown'">
                                    <ng-container *ngFor="let option of quesAns.options">
                                      <!-- <ng-container *ngIf="option.multipleDropdown"> -->
                                        <select class="custom-input" [(ngModel)]="quesAns.answer">
                                          <option *ngFor="let dropdownOption of getDropdownList(option.answer); trackBy: trackByFn"
                                            [value]="dropdownOption.name" [placeholder]="option?.placeholder">{{dropdownOption.name}}</option>
                                        </select>
                                      <!-- </ng-container> -->
                                      
                                    </ng-container>

                                  <!-- <ng-container >
                                   
                                    <ng-container *ngFor="let option of quesAns.options">
                                      <ng-container *ngIf="!option.multipleDropdown">
                                        multiple not
                                      <select class="custom-input" [(ngModel)]="quesAns.answer">
                                        <option *ngFor="let dropdownOption of getDropdownList(option.answer); trackBy: trackByFn"
                                          [value]="dropdownOption.name" [placeholder]="option?.placeholder">{{dropdownOption.name}}</option>
                                      </select>
                                    </ng-container>
                                    </ng-container>
                                  </ng-container> -->

                                </ng-container>










                                <ng-container *ngIf="!quesAns.uploading && quesAns.fileUpload">
                                  <div class="w-100">
                                    <input #fileUpload style="display: none;" type="file" id="fileInput" name="fileInput"
                                      accept=".doc,.docx,.pdf,.png,.jpg,.jpeg,.xls,.xlsx" (change)="onFileChange($event, quesAns)" multiple />
                                    <button (click)="fileUpload.click()" class="upload-btn" matTooltip="Only PDF, Word, Excel, and Image files can be uploaded">
                                      <mat-icon>attachment</mat-icon> Upload
                                    </button>
                                  </div>
                                </ng-container>
                                <div class="w-100 img_container" *ngIf="quesAns.fileUpload">
                                  <ng-container *ngIf="quesAns.uploadedFiles?.length">
                                    <div class="text-center">
                                      <ng-container *ngFor="let file of quesAns.uploadedFiles; let fileIndex = index">
                                        <div class="file-container">
                                          <div class="d-flex justify_content_between align_item_center w-100">
                                            <ng-container [ngSwitch]="sharedService.uploadedFileType(file?.name)">
                                              <div class="d-flex align_item_center w-100">
                                                <fa-icon *ngSwitchCase="'pdf'" [icon]="faFilePdf" class="file-icon" size="2x" aria-hidden="true" alt="PDF File" (click)="sharedService.openFileInNewTab(quesAns.uploadedFiles, fileIndex)"></fa-icon>
                                                <fa-icon *ngSwitchCase="'doc'" [icon]="faFileWord" class="file-icon" size="2x" aria-hidden="true" alt="Document File" (click)="sharedService.openFileInNewTab(quesAns.uploadedFiles, fileIndex)"></fa-icon>
                                                <fa-icon *ngSwitchCase="'xlsx'" [icon]="faFileExcel" class="file-icon" size="2x" aria-hidden="true" alt="Excel File" (click)="sharedService.openFileInNewTab(quesAns.uploadedFiles, fileIndex)"></fa-icon>
                                                <fa-icon *ngSwitchCase="'image'" [icon]="faFileImage" class="file-icon" size="2x" aria-hidden="true" alt="Image File" (click)="sharedService.openFileInNewTab(quesAns.uploadedFiles, fileIndex)"></fa-icon>
                                                <p class="file-name pad_10">{{ sharedService.truncateFileName(file.name) }}</p>
                                              </div>
                                              <div>
                                                <button class="delete_btn_icon" (click)="removeFile(quesAns, fileIndex)" matTooltip="Remove file">
                                                  <mat-icon>delete</mat-icon>
                                                </button>
                                              </div>
                                            </ng-container>
                                          </div>
                                        </div>
                                      </ng-container>
                                    </div>
                                  </ng-container>
                                  <ng-container *ngIf="quesAns.uploading">
                                    <mat-progress-spinner class="example-margin" color="primary" mode="indeterminate" [value]="progressValue"></mat-progress-spinner>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                  <div class="d-flex align_item_center justify_content_between">
                    <div class="button-container">
                      <button mat-flat-button class="upload-btn back-btn pad_10" (click)="previousStep()" *ngIf="activeStep > 0">Back</button>
                      <button mat-flat-button class="upload-btn pad_10" (click)="nextStep()" *ngIf="activeStep < (data?.sections.length - 1)">Next</button>
                    </div>
                    <button mat-flat-button class="upload-btn pad_10" (click)="updateSurvey()" *ngIf="activeStep === (data?.sections.length - 1)">Submit</button>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      
        <ng-template #descriptionTemplate>
          <ng-container>
            <div class="card_bg_mgn">
              <div class="card card_bg">
                <div id="description">
                  <!-- <div class="focus_label" [innerHTML]="data.description | capitalizeFirst"></div> -->
                  <div style="margin-bottom: 10px;">
                    <label>Choose topic</label>
                    <select class="custom-input" placeholder="Select topic" [(value)]="selected">
                      <option value="Climate change adaption">Climate change adaption</option>
                      <option value="Climate change mitigation">Climate change mitigation</option>
                      <option value="Energy">Energy</option>
                      <option value="Air Pollution">Air Pollution</option>
                    </select>
                  </div>

                  <button mat-flat-button class="upload-btn pad_10 start-btn mgn-up-20" (click)="startSurvey()">Get Started Now!</button>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </div>
      
    </div>
  </div>
</div>

<ng-template #congratsTemplate>
  <app-congratulations></app-congratulations>
</ng-template>
