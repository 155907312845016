import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { TemplateViewerComponent } from './template-viewer/template-viewer.component';
import { HttpClientModule } from '@angular/common/http';
import { IncompleteQuestionsDialogComponent } from './incomplete-questions-dialog/incomplete-questions-dialog.component';
import { CongratulationsComponent } from './congratulations/congratulations.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HttpClientModule, TemplateViewerComponent, IncompleteQuestionsDialogComponent, CongratulationsComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'survey';
}
