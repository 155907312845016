<section class="pad_30 section">
  <mat-dialog-content class="mat-typography">
    <div class="close_icon">
      <div class="content-container">
        <ng-container *ngIf="confirmation">
          <p class="subtitle">
            Are you sure you want to submit the survey with the following answers?
          </p>
        </ng-container>
        <ng-container *ngIf="!confirmation">
          <div class="alert-container">
            <span class="material-icons alert-icon">error_outline</span>
            Please answer the following mandatory questions:
          </div>
        </ng-container>
      </div>
      <div class="cancel_btn_icon">
        <mat-icon [mat-dialog-close]="true" (click)="closeDialog()">cancel</mat-icon>
      </div>
    </div>
    
    <div *ngIf="!confirmation">
      <ul class="pad_20">
        <li class="list" *ngFor="let question of data.incompleteQuestions">{{ question }}</li>
      </ul>
    </div>
    <div class="mgn-up-20" *ngIf="confirmation">
      <div class="content">
        <div class="about-contact-section">
          <div class="card_bg_mgn">
            <div class="card_bg">
              <mat-accordion *ngIf="data.submittedQuestions && data.submittedQuestions.length > 0">
                <div *ngFor="let sections of data.submittedQuestions; let sectionIndex = index"
                  class="survey_description_bg pad_5">
                  <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="panel_survey_title">
                        {{(sections.title | capitalizeFirst) ? (sections.title | capitalizeFirst):''}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                      <ng-container>
                        <div class="survey_description_section mgn-up-10"
                          [innerHTML]="sections.description | capitalizeFirst"></div>
                      </ng-container>
                      <div class="background_panel">
                        <ng-container *ngIf="sections?.questions?.length">
                          <div class="question-table">
                            <ng-container *ngFor="let quesAns of sections.questions; let questionIndex = index">
                              <div class="table-cell">
                                <div class="ques_num_label">{{getGlobalQuestionIndex(sectionIndex, questionIndex) + 1}}.
                                </div>
                                <div class="card_bg_custom custom_panel_expansion">
                                  <div class="question_label">
                                    <ng-container *ngIf="quesAns.text">
                                      <span>{{quesAns.text | capitalizeFirst}}</span>
                                      <span *ngIf="quesAns.required" class="required-asterisk">*</span>
                                      <div class="question_description_section mgn-up-10" *ngIf="quesAns.description">
                                        {{quesAns.description | capitalizeFirst}}</div>
                                    </ng-container>
                                  </div>
                                  <div class="input-container">
                                    <ng-container *ngIf="quesAns.type === 'checkbox'">
                                      <ng-container *ngFor="let option of quesAns.options; let optionIndex = index">
                                        <mat-checkbox class="example-margin" [disabled]="true"
                                          [checked]="quesAns.answer.includes(option)">
                                          {{option | capitalizeFirst}}
                                        </mat-checkbox>
                                      </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="quesAns.type === 'radio'">
                                      <mat-radio-group aria-label="Select an option" class="d-grid" [disabled]="true">
                                        <ng-container *ngFor="let option of quesAns.options">
                                          <mat-radio-button [disabled]="true" [value]="option.answer"
                                            [checked]="quesAns.selectedOption === option.answer">{{option |
                                            capitalizeFirst}}</mat-radio-button>
                                        </ng-container>
                                      </mat-radio-group>
                                    </ng-container>
                                    <ng-container *ngIf="quesAns.type === 'textarea'">
                                      <mat-form-field appearance="outline">
                                        <textarea matInput class="question_input" [readonly]="true"
                                          [value]="quesAns.answer" [placeholder]="quesAns?.placeholder"></textarea>
                                      </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngIf="quesAns.type === 'input'">
                                      <mat-form-field appearance="outline">
                                        <input matInput [type]="quesAns.inputType || 'text'" [readonly]="true"
                                          [value]="quesAns.answer" [placeholder]="quesAns?.placeholder">
                                      </mat-form-field>
                                    </ng-container>
                                    <div class="w-100 img_container" *ngIf="quesAns.fileUpload">
                                      <ng-container *ngIf="quesAns.uploadedFiles && quesAns.uploadedFiles.length">
                                        <div class="text-center">
                                          <ng-container *ngFor="let file of quesAns.uploadedFiles; let fileIndex = index">
                                            <div class="file-container">
                                              <div class="d-flex justify_content_between align_item_center w-100">
                                                <ng-container [ngSwitch]="sharedService.uploadedFileType(file?.name)">
                                                  <div class="d-flex align_item_center w-100">
                                                    <!-- <a [href]="file?.url" target="_blank"> -->
                                                      <fa-icon *ngSwitchCase="'pdf'" [icon]="faFilePdf"
                                                        class="file-icon" size="2x" aria-hidden="true"
                                                        alt="PDF File" (click)="sharedService.openFileInNewTab(quesAns.uploadedFiles, fileIndex)"></fa-icon>
                                                      <fa-icon *ngSwitchCase="'doc'" [icon]="faFileWord"
                                                        class="file-icon" size="2x" aria-hidden="true"
                                                        alt="Document File" (click)="sharedService.openFileInNewTab(quesAns.uploadedFiles, fileIndex)"></fa-icon>
                                                      <fa-icon *ngSwitchCase="'xlsx'" [icon]="faFileExcel"
                                                        class="file-icon" size="2x" aria-hidden="true"
                                                        alt="Excel File" (click)="sharedService.openFileInNewTab(quesAns.uploadedFiles, fileIndex)"></fa-icon>
                                                      <fa-icon *ngSwitchCase="'image'" [icon]="faFileImage"
                                                        class="file-icon" size="2x" aria-hidden="true"
                                                        alt="Image File" (click)="sharedService.openFileInNewTab(quesAns.uploadedFiles, fileIndex)"></fa-icon>
                                                    <!-- </a> -->
                                                    <p class="file-name pad_10">{{ sharedService.truncateFileName(file?.name) }}</p>
                                                  </div>
                                                </ng-container>
                                              </div>
                                            </div>
                                          </ng-container>
                                        </div>
                                      </ng-container>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </div>
              </mat-accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="close_button">
      <button mat-button *ngIf="!confirmation" (click)="closeDialog()" class="close_btn">Close</button>
      <button mat-button *ngIf="confirmation" (click)="confirmSubmit()" class="close_btn m-20">Yes</button>
      <button mat-button *ngIf="confirmation" (click)="closeDialog()" class="close_btn">No</button>
    </div>
  </mat-dialog-content>
</section>