import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'app-congratulations',
  standalone: true,
  imports: [],
  templateUrl: './congratulations.component.html',
  styleUrl: './congratulations.component.scss'
})
export class CongratulationsComponent {

}
