import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TemplateViewerService {
  constructor(private http: HttpClient) { }
  apiUrl= environment.api_url;
  surveyUrl = environment.surveyUrl
  private api = `${this.apiUrl}/survey/encrypted`;

  //apis
  getSmaSurvey(surveyId: number): Observable<any> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*', // This won't help with CORS
      'Content-Type': 'application/json'
    });
    return this.http.get<any>(`${this.api}/${surveyId}`, { headers });
  }

  UpdateSurvey(surveyData: any) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*', // This won't help with CORS
      'Content-Type': 'application/json'
    });
    return this.http.post<any>(`${this.surveyUrl}`, surveyData, { headers });
  }

  fileUpload(formData: FormData): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append( 'Accept', '*/*' );
    headers = headers.append('Ssuite-Type', 'File-Upload');
    let url =this.apiUrl + '/images/uploadFiles';
    return this.http.post(url, formData, { headers: headers,responseType:'text' })
  }
  
}

